import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import DbTasks from '../../db/DbTasks';
import Grid from '@material-ui/core/Grid';
import { FaQuestion } from 'react-icons/fa';
import CardContainerSmall from '../Reusable/CardContainerSmall';
import { makeStyles } from '@material-ui/core';
import { getDataFromDatabase } from '../../db/api';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        margin: theme.spacing(2),
    },
    cardColor: {
        backgroundColor: '#45bbdf'
    }
}));

function WegweisserSubGroups(props) {
    const classes = useStyles();
    const [groups, setGroups] = useState([]);

    useEffect(() => {
        let id = parseInt(props.match.params.id);
        getDataFromDatabase(`api/Wegweisser/WegSubGroups?groupId=${id}`, {}, { method: "GET" })
            .then(res => {
                setGroups(res);
            })
        return () => {
            console.log("Running cleanup function !");
        }
    }, [props.match.params.id]);

    return (
        <div className={classes.root}>
            <Grid container spacing={1} alignContent='center' justify='center'>
                        {groups.length > 0 && groups.map(el =>
                            <Grid item lg={3} md={4} sm={6} xs={12} key={el.id} onClick={() => props.history.push(`/wegweisseranswer/${el.id}`)} data-testid={`el-${el.id}`}>
                                <CardContainerSmall
                                    key={el.id}
                                    title={el.name}
                                    number={el.totalQuestions}
                                    subtitle={el.totalQuestions > 1 ? "AntrÃ¤ge" : "AntrÃ¤ge"}
                                    class={"blue"}
                                    mainIcon={<FaQuestion size={"2em"} color="white" />}
                                />
                            </Grid>
                        )}
            </Grid>
        </div>
    )
}

export default withRouter(WegweisserSubGroups);