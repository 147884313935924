import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Divider from '@material-ui/core/Divider';
import MailIcon from '@material-ui/icons/Mail';
import IconButton from "@material-ui/core/IconButton";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import {
    MenuIcon,
    NotificationsIcon,
    GoBack
} from "../../bundles/IconsBundle";
import NestedList from '../Reusable/TreeView';
import { toggleDrawer, setInitialDataFromLocalDb } from '../../actions/AppActions';
import { addNewNotification } from '../../actions/NotificationActions';
import { useSelector, useDispatch } from 'react-redux'
import { fade, makeStyles } from '@material-ui/core/styles';

import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MoreIcon from '@material-ui/icons/MoreVert';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { getBeraterId, logOut } from '../../helpers/HelperFunctions';
import { withRouter } from 'react-router-dom';

import UpdateButton from '../Update';
import DbTasks from '../../db/DbTasks';
// const useStyles = makeStyles({
//     list: {
//         width: 250,
//     },
//     fullList: {
//         width: 'auto',
//     },
// });

const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1,
        position: 'sticky',
        top: 0,
        zIndex: 1200
    },
    menuButton: {
        marginRight: theme.spacing(0),
    },
    title: {
        display: 'block',
        // [theme.breakpoints.up('sm')]: {
        //     display: 'block',
        // },
    },
    notificationMenu: {
        width: 230,
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
}));

function SwipeableTemporaryDrawer(props) {
    //const classes = useStyles();
    // const [state, setState] = React.useState({
    //     left: false,
    // });
    let dispatch = useDispatch();
    let drawerOpen = useSelector(state => state.app.drawerOpen) || false;
    const chatConnection = useSelector(state => state.app.chatConnection);

    // const toggleDrawerLocal = (anchor, open) => (event) => {
    //     if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
    //         return;
    //     }

    //     //setState({ ...state, [anchor]: open });
    // };

    useEffect(() => {

        //Load data from local database into state
        dispatch(setInitialDataFromLocalDb());
        //dispatch(setInitialDataFromLocalDb());
        return () => {
            console.log("Cleanup function running !");
        }
    }, []);

    // useEffect(() => {
    //     chatConnection && chatConnection.on("ShowMessageNotification", function (user, userName, message, notificationForBeraterId) {

    //         let data = {
    //         text: `You have new message from ${userName}`,
    //         description: message,
    //         type: user,
    //         notificationForBeraterId:notificationForBeraterId
    //         }

    //         let flag = true;
    //         let db = new DbTasks();

    //         db.getAllNotifications().then(s =>{
    //             s.forEach(d=>{
    //                 if(d.type==user)
    //                 {
    //                     flag=false;
    //                 }
    //                 return;
    //             })
    //             if(flag === true)
    //             {
    //                 dispatch(addNewNotification(data));
    //             }
    //         })
    //     });
    // },
    //     [chatConnection]
    // );


    let showUpdateBtn = useSelector(state => state.app.serviceWorkerUpdated);
    let beraterName = useSelector(state => state.chat.beraterName);
    let groupName = useSelector(state => state.chat.groupName);
    console.log(groupName);

    const signOut = () => {
        logOut()
    }

    const list = (anchor) => (
        <div
            className={clsx(classes.list, {
                [classes.fullList]: anchor === 'top' || anchor === 'bottom',
            })}
            role="presentation"
        // onClick={() => dispatch(toggleDrawer(true))}
        // onKeyDown={() => dispatch(toggleDrawer(true))}
        >
            <NestedList menuOpen={toggleDrawer} />
            {/* <List>
                {['Chat', 'FirmaInfo', 'Urlaub', 'Krankenmeldung', 'Leistungspremie', 'KVP', 'Wegweisser', 'Gallerie'].map((text, index) => (
                    <ListItem button key={text}>
                        <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
                        <ListItemText primary={text} />
                    </ListItem>
                ))}
            </List> */}
            <Divider />
        </div>
    );

    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorElNotification, setAnchorNotification] = React.useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

    const isMenuOpen = Boolean(anchorEl);
    const isNotificationMenuOpen = Boolean(anchorElNotification);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
        setAnchorNotification(null);
    };

    // const handleNotificationMenuClose = (event) => {
    //     setAnchorNotification(null);

    // }

    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const handleNotificationsMenuOpen = (event) => {
        setAnchorNotification(event.currentTarget)
    }

    const changePassword = () =>{
        window.location.href="/changepassword";
    }

    let notificationList = useSelector(state => state.notifications.list) || []; //getting notification list from global state with selector
    // useEffect(()=>{
    //     if (notificationList.length > 0)
    //     {
    //         notificationList = notificationList.filter(e => e.seen === 0); //filtering
    //     }
    // },[]);

    if (notificationList.length > 0) notificationList = notificationList.filter(e => e.seen === 0 && e.notificationForBeraterId===getBeraterId());
    //console.log("Notification list : ", notificationList);

    const menuId = 'primary-search-account-menu';
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            //anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            id={menuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            {/* <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
            <MenuItem onClick={handleMenuClose}>My account</MenuItem> */}
            <MenuItem onClick={signOut}>Ausloggen</MenuItem>
            <MenuItem onClick={changePassword}>Kennwort Ã¤ndern</MenuItem>
        </Menu>
    );

    const renderMenuNotifications = (
        <Menu
            className={classes.notificationMenu}
            anchorEl={anchorElNotification}
            //anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            id={"notificationMenu"}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isNotificationMenuOpen}
            onClose={handleMenuClose}
        >
            {notificationList && notificationList.map(item => {
                return (<MenuItem key={item.id} onClick={handleMenuClose}>
                    <ListItemIcon style={{maxWidth: '30px', minWidth: '25px'}}>
                        <NotificationsIcon fontSize="small"  />
                    </ListItemIcon>
                    <Typography variant="inherit" noWrap>
                        {item.text}
                    </Typography>
                </MenuItem>)
            })}
            <MenuItem key={-1} onClick={() => props.history.push("/notifications")}>
                    {/* <ListItemIcon>
                        <NotificationsIcon fontSize="small" />
                    </ListItemIcon> */}
                    <Typography variant="inherit" noWrap>
                        {"Benachrichtigungen"}
                    </Typography>
                </MenuItem>

            {/* <MenuItem onClick={handleMenuClose}>Note 2</MenuItem> */}
        </Menu>
    );

    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            <MenuItem
            onClick={() => props.history.push("/chatlist")}
            >
                <IconButton aria-label="show 4 new mails" color="inherit">
                    <Badge  color="secondary">
                        <MailIcon />
                    </Badge>
                </IconButton>
                <p>Nachrichten</p>
            </MenuItem>
            <MenuItem onClick={() => props.history.push("/notifications")}>
                <IconButton aria-label="show 11 new notifications" color="inherit">
                    <Badge badgeContent={notificationList.length} color="secondary">
                        <NotificationsIcon />
                    </Badge>
                </IconButton>
                <p>Benachrichtigung</p>
            </MenuItem>
            <MenuItem onClick={handleProfileMenuOpen}>
                <IconButton
                    aria-label="account of current user"
                    aria-controls="primary-search-account-menu"
                    aria-haspopup="true"
                    color="inherit"
                >
                    <AccountCircle />
                </IconButton>
                <p>Profil</p>
            </MenuItem>
        </Menu>
    );

    const appBarTitle = () => {
        var location = window.location.pathname;
        if(location.lastIndexOf("/") !== 0){
            location = "/" + location.slice(1, location.indexOf("/", 1) + 1);
        }
        switch(location){
            case "/home": return "Startseite"
            case "/notifications": return "Notifications"
            case "/urlaub": return "Urlaub"
            case "/krankenmeldung": return "Krankenmeldung"
            case "/monatsleistung": return "Monatsleistung"
            case "/tagesleistung/": return "Tagesleistung"
            case "/gallery": return "Gallerie - Ãbersicht"
            case "/gallery/": return "Galerie Vorschau"
            case "/kvp": return "KVP"
            case "/wegparent/": return "Wegweiser"
            case "/wegweisser/": return "Wegweiser"
            case "/wegweisseranswer/": return "Wegweiser"
            case "/arbeitszeit": return "Schichtplan"
            case "/veranstaltungen": return "Veranstaltungen"
            case "/allgemein": return "Allgemein"
            case "/urlauboverview": return "UrlaubsÃ¼bersicht"
            case "/urlauboverviewplanned": return "UrlaubsÃ¼bersicht"
            case "/zeiterfassung": return "Zeiterfassung"
            case "/mitarbeiterhandbuch": return "Mitarbeiterhandbuch"
            case "/chatlist": return "Chat"
            case "/groupchat/": return groupName
            case "/chat/": return beraterName
            case "/mitarbeitergallery": return "Gallerie - Fotos hochgeladen von Mitarbeiter"
            case "/mitarbeitergallery/": return "Gallerie - Fotos hochgeladen von Mitarbeiter"
            case "/mitarbeitergallerypreview/" : return "Gallerie - Fotos hochgeladen von Mitarbeiter"
            case "/changepassword":return "Kennwort Ã¤ndern "

            default: return "MitarbeiterApp"
        }
    }

    return (
        <div className={classes.grow}>
            <AppBar position="sticky">
                <Toolbar>
                    <IconButton
                        onClick={() => dispatch(toggleDrawer(true))}
                        edge="start"
                        className={classes.menuButton}
                        color="inherit"
                        aria-label="open drawer"
                    >
                        <MenuIcon />
                    </IconButton>
                    <IconButton
                        onClick={() => props.history.goBack()}
                        edge="start"
                        className={classes.menuButton}
                        color="inherit"
                        aria-label="open drawer"
                    >
                        <GoBack />
                    </IconButton>
                    <Typography id="app-bar-title" className={classes.title} variant="h6" noWrap>
                        {appBarTitle()}
                    </Typography>

                    <SwipeableDrawer
                        anchor={"left"}
                        open={drawerOpen}
                        onClose={() => dispatch(toggleDrawer(true))}
                        onOpen={() => dispatch(toggleDrawer(true))}
                    >
                        {list("left")}
                    </SwipeableDrawer>
                    {/* <div className={classes.search}>
                        <div className={classes.searchIcon}>
                            <SearchIcon />
                        </div>
                        <InputBase
                            placeholder="Searchâ¦"
                            classes={{
                                root: classes.inputRoot,
                                input: classes.inputInput,
                            }}
                            inputProps={{ 'aria-label': 'search' }}
                        />
                    </div> */}
                    <div className={classes.grow} />
                    <div className={classes.sectionDesktop}>
                    {showUpdateBtn && <UpdateButton />
                                }
                        {/* <IconButton aria-label="show 4 new mails" color="inherit">
                            <Badge badgeContent={4} color="secondary">
                                <MailIcon />
                            </Badge>
                        </IconButton> */}
                        <IconButton
                            onClick={handleNotificationsMenuOpen}
                            aria-label="show new notifications"
                            color="inherit">
                            <Badge badgeContent={notificationList.length} color="secondary">
                                <NotificationsIcon />
                            </Badge>
                        </IconButton>
                        <IconButton
                            edge="end"
                            aria-label="account of current user"
                            aria-controls={menuId}
                            aria-haspopup="true"
                            onClick={handleProfileMenuOpen}
                            color="inherit"
                        >
                            <AccountCircle />
                        </IconButton>
                    </div>
                    <div className={classes.sectionMobile}>
                    {showUpdateBtn && <UpdateButton />}
                        <IconButton
                            aria-label="show more"
                            aria-controls={mobileMenuId}
                            aria-haspopup="true"
                            onClick={handleMobileMenuOpen}
                            color="inherit"
                        >
                            <MoreIcon />
                        </IconButton>
                    </div>
                </Toolbar>
            </AppBar>
            {renderMobileMenu}
            {renderMenu}
            {renderMenuNotifications}
        </div>
    );
}

export default withRouter(SwipeableTemporaryDrawer);
