import { Grid, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import { FaUmbrellaBeach } from 'react-icons/fa';
import { withRouter } from 'react-router-dom';
import CardContainerDates from '../Reusable/CardContainerDates';
import moment from 'moment-with-locales-es6';
import { getBeraterId } from '../../helpers/HelperFunctions';
import DbTasks from '../../db/DbTasks';
import {getDataFromDatabase} from '../../db/api';
import Notifications from '../Reusable/Notifications';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        margin: theme.spacing(2),
    },
    sectionHeading: {
        borderBottom: '2px solid #1976d2',
        textTransform: 'uppercase',

    }
}));

function UrlaubOverview() {
    const classes = useStyles();
    const [state, setUrlaubData] = useState({
        usedDates: [],
        plannedDates: [],
        urlaubRequest: [],
        rejectedUrlaubDates:[],
        betriebRuhe:[]
    })
    // let [showUsed, showUsedDates] = useState([]) //useState(["2021-01-11T00:00:00", "2021-01-12T00:00:00", "2021-01-13T00:00:00","2021-01-14T00:00:00" ,"2021-01-15T00:00:00", "2021-01-16T00:00:00"]);
    let showUsedDays = window.location.pathname === "/urlauboverview" ? true : false;


    useEffect(() => {
        let beraterId = getBeraterId();
        getDataFromDatabase("api/Urlaub/GetUrlaubInfo", { beraterId: beraterId }, { method: "POST" })
            .then(res => {
                console.log("response---->",res);
                let _usedDates = res.usedDates;
                if(res.betriebRuhe.length > 0)
                    res.betriebRuhe.map((el) => _usedDates.push(el));
                let sortedUsedDates = _usedDates.sort((a, b) => new Date(a) - new Date(b));
                //console.log("sortedUsedDates",sortedUsedDates);
                setUrlaubData({ ...state, usedDates: sortedUsedDates, plannedDates: res.plannedDates, urlaubRequest: res.urlaubRequest, rejectedUrlaubDates:res.rejectedUrlaubDates, betriebRuhe:res.betriebRuhe })
            })
        return () => {
            console.log("Running cleanup function !");
        }
    }, [])

    const handleDateClick = (el) =>{
       Notifications('info',el);
    }

    return (
        <div className={classes.root}>
            <Grid container
                direction="row"
                justify="center"
                alignItems="flex-start"
                spacing={1}
                data-testid="card"
            >
                {showUsedDays && (
                    <>
                        {state.usedDates.length > 0 &&
                            <Grid item xs={12}>
                                <p className={classes.sectionHeading}>genommener Urlaub</p>
                            </Grid>
                        }

                        {state.usedDates.map((el, index) => (

                            <Grid item lg={3} md={4} sm={6} xs={12} key={index}>
                                <CardContainerDates
                                    key={index}
                                    useMini={true}
                                    title={moment(el).locale('de').format("dddd MMMM")}
                                    number={moment(el).locale('de').format("DD")}
                                    subtitle={moment(el).locale('de').format("YYYY")}
                                    class={"white"}
                                    mainIcon={<FaUmbrellaBeach size={"1em"} color="red" />}
                                />
                            </Grid>
                        ))}
                    </>
                )}

                {!showUsedDays && (
                    <>
                        {state.plannedDates.length > 0 &&
                            <Grid item xs={12}>
                                <p className={classes.sectionHeading}>genehmigter Urlaub</p>
                            </Grid>
                        }
                        {state.plannedDates.map((el, index) => (

                            <Grid item lg={3} md={4} sm={6} xs={12} key={index} >
                                <CardContainerDates
                                    key={index}
                                    useMini={true}
                                    title={moment(el).locale('de').format("dddd MMMM")}
                                    number={moment(el).locale('de').format("DD")}
                                    subtitle={moment(el).locale('de').format("YYYY")}
                                    class={"white"}
                                    mainIcon={<FaUmbrellaBeach size={"1em"} color="red" />}
                                />
                            </Grid>
                        ))}
                        {state.urlaubRequest.length > 0 &&
                            <Grid item xs={12}>
                                <p className={classes.sectionHeading}>geplanter Urlaub (warten auf Genehmigung)</p>
                            </Grid>
                        }
                        {state.urlaubRequest.map((el, index) => (

                            <Grid item lg={3} md={4} sm={6} xs={12} key={index} >
                                <CardContainerDates
                                    key={index}
                                    useMini={true}
                                    title={moment(el).locale('de').format("dddd MMMM")}
                                    number={moment(el).locale('de').format("DD")}
                                    subtitle={moment(el).locale('de').format("YYYY")}
                                    class={"white"}
                                    mainIcon={<FaUmbrellaBeach size={"1em"} color="red" />}
                                />
                            </Grid>
                        ))}
                        {state.rejectedUrlaubDates.length > 0 &&
                            <Grid item xs={12}>
                                <p className={classes.sectionHeading}>geplanter Urlaub (abgelehnt)</p>
                            </Grid>
                        }
                        {state.rejectedUrlaubDates.map((el, index) => (

                            <Grid item lg={3} md={4} sm={6} xs={12} key={index} onClick={()=>handleDateClick(el.descriptionSubject)}>
                                <CardContainerDates
                                    key={index}
                                    useMini={true}
                                    title={moment(el.date).locale('de').format("dddd MMMM")}
                                    number={moment(el.date).locale('de').format("DD")}
                                    subtitle={moment(el.date).locale('de').format("YYYY")}
                                    class={"white"}
                                    mainIcon={<FaUmbrellaBeach size={"1em"} color="red"/>}
                                />
                            </Grid>
                        ))}
                    </>
                )}

            </Grid>
        </div>
    )
}

export default withRouter(UrlaubOverview);