//import styles from '../../assets/styles/FensterDetails.module.css';
import React, { useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
//import { Text } from '../extensions/LanguageContainer';
import TakePhoto from '../extensions/TakePhoto';
//import { FotoAdd, SendEmail } from '../../actions/TaskActions';
import { useDispatch } from 'react-redux';
import { ButtonGroup, CircularProgress, IconButton, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import { PhotoCameraIcon, ImageIcon, Delete } from '../../bundles/IconsBundle';
import DbTasks from '../../db/DbTasks';
import { getSession } from '../../helpers/HelperFunctions';
import List from '@material-ui/core/List';

import moment from 'moment-with-locales-es6';
import { withRouter } from 'react-router-dom';
import CardContainerDates from '../Reusable/CardContainerDates';
import { FaSortDown, FaSortUp, FaStethoscope, FaUpload, FaPhotoVideo } from 'react-icons/fa';
import clsx from 'clsx';
import { green } from '@material-ui/core/colors';
import {getDataFromDatabase} from '../../db/api';
import { getBase64, imageToDataUri } from '../../helpers/HelperFunctions';
import BackdropSpinner from '../Reusable/BackdropSpinner';
import {  FaFolderOpen, FaImage } from 'react-icons/fa';

const useStyles = makeStyles((theme) => ({
    root: {
        //display: 'flex',
        //flexDirection: 'column',
        //alignItems: 'center',
        // '& > *': {
        //     margin: theme.spacing(1),
        // },
        margin: theme.spacing(1),
        color: "#ffffff",
        // textAlign: '-webkit-center'
    },
    groupedBtn: {
        margin: theme.spacing(0),
        //maxWidth: '600px',

    },
    button: {
        backgroundColor: "#1976d2",
        '&:hover': {
            backgroundColor: "#1976d2",
        },
        color: "#FFFFFF",
        //fontSize: "18px",
        margin: theme.spacing(0)
    },
    wrapper: {
        margin: theme.spacing(0),
        position: 'relative',
    },
    buttonSuccess: {
        backgroundColor: "#3cb498",
        '&:hover': {
            backgroundColor: "#0eb58e",
        },
        color: "#FFFFFF",
        //fontSize: "22px",
        margin: theme.spacing(0)
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    sectionHeading: {
        borderBottom: '2px solid #1976d2',
        textTransform: 'uppercase',
        color: '#000000',
        marginTop: '10px'
    },
    show: {
        display: 'block',
    },
    hide: {
        display: 'none',
    },
    cardRoot: {
        minWidth: 245,
        height: 245,
        listStyleType: 'none'
    },
}));

function MitarbeiterGallery(props) {

    const classes = useStyles();
    let cookie = getSession();
    let beraterId = '';
    if(cookie)
    {
        beraterId = parseInt(cookie.nameid);
    }
    const [loading, setLoading] = React.useState(false);
    const [success, setSuccess] = React.useState(false);
    const [folders, setFolders] = useState([]);

    let [showUsed, showUsedDates] = useState([]);
    const buttonClassname = clsx({
        [classes.buttonSuccess]: success,
        [classes.button]: !success
    });

    const dispatch = useDispatch();

    const [state, setState] = React.useState({
        showForm: false,
        TakePhotoOpen: false,
        images: [],
        dialog: false,
        signDialogOpen: false,
        valueFenster: "ja",
        valueGlas: "ja",
        assemblyCompleted: "ja",
        constructionSiteClean: "ja",
        deliveryWithoutDamage: "ja",
        abhnameFreiText: "",
        sendMailDialogOpen: false,
        subject: "",
        email: "",
        message: "",
        emailValid: true

    });



    // useEffect(() => {
    //     getDataFromDatabase("api/Urlaub/GetUrlaubInfo", { beraterId: beraterId }, { method: "POST" })
    //         .then(res => {
    //             console.log(res);
    //             //setUsedDays(res.krankDates.length);
    //             if(res!= null)
    //             showUsedDates(res.krankDates);
    //             //setUsedDays(json.id);
    //         })
    //     // fetch('https://localhost:44393/api/Urlaub/GetUrlaubInfo', requestOptions)
    //     //     .then(response => response.json())

    //     return () => {
    //         console.log("Running cleanup function !");
    //     }
    // }, []);

    useEffect(() => {
        let id = parseInt(props.match.params.id);   
        console.log("params",props.match.params);
        getDataFromDatabase(`api/Gallery/GetGalleryMitarbeiterFoldersByMonth?id=${id}`, {}, { method: "GET" })
          .then(res => {
            res.sort((a, b) => (a.folderName < b.folderName) ? 1 : -1)
            setFolders(res);
            setLoading(false);
            //console.log("res1",res);
        });

        document.getElementById("app-bar-title").innerHTML = `Gallerie - Fotos hochgeladen von Mitarbeiter - ${id}`;
        //console.log("naslov",naslov);
        return () => {
          console.log("Running cleanup function !");
        }
      }, [])


    let file_input = [];


    const handleTextChange = (e) => {
        setState({ ...state, [e.target.name]: e.target.value })
    }

    const fotoMachen = () => {
        setState({ ...state, TakePhotoOpen: true, images: state.images });
    };

    const closeTakePhoto = () => {
        setState({ ...state, TakePhotoOpen: false });
    };

    const selectFile = event => {
        event.preventDefault();
        //console.log("FI", file_input);
        file_input = document.createElement("input");
        file_input.multiple = "multiple";
        file_input.id = 'file_input_id';
        //file_input.setAtribute("data-testid", "file_input_id");
        file_input.addEventListener(
            "change",
            uploadFile.bind(this),
            false
        );
        file_input.type = "file";
        file_input.click();
    };


    const uploadFile = () => {
        Object.values(file_input.files).forEach(src => {
            let fileName = src.name;
            getBase64(src).then(src =>
            // dispatch(FotoAdd(null, src)
            {
                let img = state.images;
                img.push({ data: src, fileName: fileName });
                setState({ ...state, images: img })

                //setState({ ...state, images: [...state.images, { data: src, fileName: fileName }] })
            }
                //src
            );
        });
    };

    const handleTakeScreenshot = (imageSrc) => {
        const img = new Image();
        img.src = imageSrc;
        img.onload = () => {
            let extension = img.src.startsWith('data:image/png') === true ? ".png" : ".jpg";
            var newDataUri = imageToDataUri(img, img.width, img.height, extension);
            //dispatch(FotoAdd(null, newDataUri))
        };

    }


    const removeImage = (idToRemove) => {
        //dispatch(FotoRemove(idToRemove));
        //console.log("Image to remove : ", idToRemove);
        let imagesWithoutDeletedOnes = state.images.filter(e => e.fileName !== idToRemove);
        //console.log("Deleted image name : ", idToRemove);

        //console.log("Images left : ", imagesWithoutDeletedOnes);
        setState({ ...state, images: imagesWithoutDeletedOnes });
    }


    const uploadData = () => {
        console.log("imagess :", state.images);
        setSuccess(false);
        setLoading(true);
        // let subject = document.getElementById("subject").value;
        // if (subject === "") {
        //     setSuccess(false);
        //     setLoading(false);
        //     alert("Please enter Subject value");
        //     return;
        // }
        let data = {
            Attachments: state.images,
            BeraterId: beraterId,
            Subject: null,
            endpoint: "api/Gallery/UploadPhotos",
        }
        new DbTasks().dataUpload(data)
            .then(res => {
                if (res) {
                    setSuccess(true);
                    setLoading(false);
                }
                else {
                    setSuccess(false);
                    setLoading(false);
                    alert("Upload photos went wrong.");
                }
            }).catch(() => {
                setSuccess(false);
                setLoading(false);
                alert("Upload photos went wrong.");
            })
    }


    return (
        <div className={classes.root}>
            <Button
                variant="contained"
                //color="primary"
                onClick={() => setState({ ...state, showForm: !state.showForm })}
                startIcon={<FaPhotoVideo size={"1em"} color="blue" />}
                endIcon={state.showForm ? <FaSortUp color="blue" style={{ marginTop: '5px' }} /> : <FaSortDown color="blue" style={{ marginBottom: '5px' }} />}
                style={{marginBottom:'20px'}}
            >
                {"Fotos hochladen"}
            </Button>
            {/* <Grid container spacing={0} justify='center' className={state.showForm ? classes.show : classes.hide} >
                <Grid item lg={6} md={10} sm={10} xs={11}> */}
            <Grid container spacing={0} justify="center">

                <Grid item lg={6} md={8} sm={10} xs={11} className={state.showForm ? classes.show : classes.hide} data-testid="form-grid">
                    <Grid item xs={12}>
                        
                    </Grid>
                    <Grid container spacing={1} justify='center'>
                        <Grid item lg={12} md={12} sm={12} xs={12} style={{
                            marginTop: "1.2rem"
                        }}>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <div className={classes.groupedBtn}>
                                <ButtonGroup fullWidth color="primary" variant="contained">
                                    <Button
                                        data-testid="open-camera"
                                        variant="contained"
                                        //color="primary"
                                        onClick={fotoMachen}
                                        startIcon={<PhotoCameraIcon />}

                                    >
                                        {"Kamera Ã¶ffnen"}
                                    </Button>
                                    <Button
                                        data-testid="add-photos"
                                        onClick={selectFile}
                                        variant="outlined"
                                        startIcon={<ImageIcon />}

                                    >
                                        {"Foto auswÃ¤hlen"}
                                    </Button>
                                </ButtonGroup>
                            </div>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>

                            <TakePhoto
                                data-testid="takePhotoDialog"
                                open={state.TakePhotoOpen}
                                handleClose={closeTakePhoto}
                                handleTakeScreenshot={handleTakeScreenshot}
                            />

                            {/* Fotos */}
                            <Table>
                                <TableBody>
                                    <TableRow >
                                        <TableCell style={{ border: 0 }}>
                                            <GridList cellHeight={160} cols={3}>
                                                {state.images.map((tile, index) => (
                                                    <GridListTile cols={1} key={index} style={{ border: '1px solid #DDD' }}>
                                                        <img src={tile.data} alt="" />
                                                        <GridListTileBar
                                                            data-testid="remove-image"
                                                            onClick={() => removeImage(tile.fileName)}
                                                            actionIcon={
                                                                <IconButton >
                                                                    <Delete color="secondary" />
                                                                </IconButton>
                                                            }
                                                        />
                                                    </GridListTile>
                                                ))}
                                            </GridList>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>

                            <div className={classes.wrapper}>
                                {/* <div className={classes.wrapper}> */}
                                <Button
                                    data-testid={"upload-data"}
                                    disabled={loading}
                                    //data-testid="loginId"
                                    onClick={uploadData}
                                    className={buttonClassname}
                                    startIcon={<FaUpload />}
                                    variant="contained" fullWidth >
                                    Foto hochladen
                        </Button>
                                {loading && <CircularProgress size={30} className={classes.buttonProgress} />}
                            </div>
                        </Grid>

                        {/* <List> */}

                        {/* </List> */}
                    </Grid>
                </Grid>
            </Grid>

            <Grid container spacing={1} alignContent='center' justify='center' data-testid="content" style={{marginTop:"10px"}} >
        {folders && folders.map((el) => (
          <Grid item lg={3} md={4} sm={6} xs={12} key={el.folderName}>
            <GridListTile key={el.id} className={classes.cardRoot}>
              {el.base64 !== null ? <img src={`data:image/${el.extension};base64,${el.base64}`} style={{ width: "100%" }} alt={"Gallery"} /> :
                <div className={classes.cardIcon} data-testid={`optional-div-${el.id}`}><FaImage size={"5em"} color="grey" /></div>}

              <GridListTileBar
                className={classes.cardText}
                title={ el.folderName == 0 ? moment().month(0).locale('de').format("MMMM") : moment().month(el.folderName-1).locale('de').format("MMMM")}
                subtitle={<span>{el.description}</span>}
                key={el.folderName}
                actionIcon={
                  <div>
                    <IconButton onClick={() => props.history.push("/mitarbeitergallerypreview/" + el.id+ "/"+el.folderName)} className={classes.icon} data-testid={`icon-button-${el.id}`}>
                      <FaFolderOpen size={"2em"} color="white" />
                    </IconButton>
                  </div>
                }
              />
            </GridListTile>
          </Grid>
        ))}
      </Grid>
      <BackdropSpinner showLoading={loading} />

        </div>
    )
}

export default withRouter(MitarbeiterGallery);