import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import { Grid, Input } from '@material-ui/core';
import { withRouter } from 'react-router';
import DbTasks from '../../db/DbTasks';
import BackdropSpinner from '../Reusable/BackdropSpinner';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import { NotificationsIcon, Remove } from '../../bundles/IconsBundle';
import { getDataFromDatabase } from '../../db/api';
import Fab from '@material-ui/core/Fab';
import PhoneIcon from '@material-ui/icons/Phone';
import Typography from '@material-ui/core/Typography'
import { getBeraterId, getBeraterName } from '../../helpers/HelperFunctions';
import Button from '@material-ui/core/Button';
import { FaPlus } from 'react-icons/fa';
import Modal from '../Reusable/Modal/CustomModal'
import TextField from '@material-ui/core/TextField';
import { MultiSelect } from "react-multi-select-component";
import Notifications from '../Reusable/Notifications';
import CreateIcon from '@material-ui/icons/Create';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Dialog from '../Reusable/Dialog';
const useStyles = makeStyles((theme) => ({
    root: {
        width: '95%',
        //maxWidth: '36ch',
        backgroundColor: theme.palette.background.paper,

        flexGrow: 1,
        margin: 'auto'
    },
    inline: {
        display: 'inline',
    },
    availableStatus: {
        width: 10,
        height: 10,
        backgroundColor: 'green',
        borderRadius: '40%'
    },
    rootSearch: {
        margin: theme.spacing(1),
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: '93%',
        border: '1px solid #cce0ff'
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
        color: '#1976d2',
    },
    button: {
        marginLeft:'1%',
        marginBottom:'0.3%'
    },
}));
  

function UsersList(props) {
    const classes = useStyles();
    //const [usersList, setUsersList] = useState([]);
    const [selected, setSelected] = useState([]);
    const [localState, setLocalState] = useState({
        usersList: [],
        showLoading: true,
        reload: false,
        showClear: false,
        groups:[],
        chatGroups:[],
        //used for displaying reminder notification, task #9748
        reminderCount:0,
        //used for displaying reminder group, task #9748
        reminderData:{
            id:getBeraterId(),
            beraterName:getBeraterName(),
            telefonFirma:null,
            picture:null,
        },
        modalOpen:false,
        options:[{}],
        newGroupName:"",
        isGroupEdit:false,
        editGroupId:0,
        confirmationOpen:false,
        groupToLeave:0,
    });

    // const [showLoading, setLoading] = useState(true);
    // const [reload, setReload] = useState(false);
    // const [showClear, setClear] = useState(false);

    useEffect(() => {
        //alert("pozvao se use")
        let searchParam = document.getElementById("userSearch").value || "";
        getDataFromDatabase(`api/Chat/GetChatUsers/?userSearch=${searchParam}`, {}, { method: "GET" })
            .then(res => {
                console.log("Chat users : ", res);
                let picture = res.users.filter(s=>s.id==getBeraterId()).length > 0 ? res.users.filter(s=>s.id==getBeraterId())[0].picture : null;
                let usersForDropdown = [];
                usersForDropdown.push({label:getBeraterName(),value:getBeraterId(), disabled:true})
                res.users.map(u=>{
                    if(u.id!=getBeraterId())
                    {
                    let n = {label:u.beraterName, value:u.id}
                    usersForDropdown.push(n);
                    }
                })
                //console.log("neprocitani reminderi", res.reminders.filter(s=>s.read==0).length);
                setLocalState({...localState, usersList: res.users, showLoading: false, groups: res.groups, reminderData:{
                    id:getBeraterId(),
                    beraterName:getBeraterName(),
                    telefonFirma:null,
                    picture:picture,
                },  reminderCount:res.reminders.filter(s=>s.read==0).length,
                    chatGroups: typeof res.chatGroups === "object" && !Array.isArray(res.chatGroups) ? [] : res.chatGroups,
                    options:usersForDropdown});
                // setUsersList(res);
                // setLoading(false);
            }).catch((error) => {
                setLocalState({...localState, showLoading: false });
                //setLoading(false);
                alert("Error happened while getting chat users.")
            });
        return () => {
            console.log("Running cleanup function !");
        }
    }, [localState.reload]);


    const handleModalClose = () => {
        setLocalState({...localState, modalOpen: false });
    };
        
      const handleModalOpen = () => {
        setLocalState({...localState, modalOpen: true,isGroupEdit:false, newGroupName:"" });
        setSelected([]);
    };

    const openChatHistory = (id, beraterName) => {
        //if user opens chat with himself(reminder), then previous reminders are marked as read and they are cleared from notification
        if(id==getBeraterId()){
            //alert("cistim remindere");
            //ocisti remindere
            getDataFromDatabase(`api/Chat/ClearReminders/?userId=${id}`, {}, { method: "GET" })
            .then(res => {
                //setLocalState({ showLoading: false });
            }).catch((error) => {
                setLocalState({...localState, showLoading: false });
                //setLoading(false);
                alert("Error happened while clearing reminders.")
            });
        }
        //console.log("ls",localState);
        props.history.push(`/chat/${id}`);
    }

    const openChatGroupHistory = (groupId) =>{
        props.history.push(`/groupchat/${groupId}`);
    }
    const handleGroupEdit= (e,groupName, groupId)=>{
        //console.log("groupName",groupName)
        getDataFromDatabase(`api/Chat/GetGroupMembers/?groupId=${groupId}`, {}, { method: "GET" })
            .then(res => {
                //console.log("GrouP MEMBERS : ", res);
                var sel=[];
                res.map(s=>{
                    sel.push({label:s.berater, value:s.idBerater})
                })
                setLocalState({...localState, modalOpen:true, newGroupName:groupName, isGroupEdit:true, editGroupId:groupId})
                setSelected(sel);
            }).catch((error) => {
                //setLocalState({ ...localState,showLoading: false });
                alert("Error happened while getting group members.")
            });

            let beraters = [];
            selected.map(s=>{
                beraters.push(s.value);
            })            

        e.stopPropagation();
    }

    const handleGroupLeave = (e) =>{
        getDataFromDatabase(`api/Chat/LeaveGroup/?groupId=${localState.groupToLeave}&userId=${getBeraterId()}`, {}, { method: "GET" })
            .then(res => {
                if(res == true){
                    Notifications('success','You have left the chat group');
                    setLocalState({...localState, confirmationOpen:false, reload:true});
                }
            }).catch((error) => {
                //setLocalState({ ...localState,showLoading: false });
                Notifications('warning','Error happened while leaving the chat group!');
                alert("Error happened while leaving chat group.")
            });           
    }

    const renderChatGroup = (data) =>{
        let bId = getBeraterId();
        console.log("renderchatgroups data",data.creatorId,bId);
        let cond = data.creatorId == bId;
        console.log("cond",cond);
        return (<React.Fragment key={data.groupId}>
            <ListItem alignItems="center" data-testid={`group-${data.groupId}`} onClick={() => openChatGroupHistory(data.groupId, data.groupName)}>
                <ListItemAvatar>
                    <Avatar alt={data.groupName || ""} src={`data:image/png;base64,${null}`} />
                </ListItemAvatar>
                <ListItemText
                    primary={data.groupName || "Chat gruppe"}
                />
                {data.creatorId==getBeraterId() ? <Fab
                        size="small"
                        aria-label="Tel"
                        onClick={(e)=>handleGroupEdit(e,data.groupName, data.groupId)}
                        style={{ color: "#2074d4" }}
                    ><CreateIcon/></Fab>:''}
                    <Fab
                        size="small"
                        aria-label="Tel"
                        onClick={(e) => setConfirmationOpen(e,true,data.groupId)}
                        // onClick={(e)=>handleGroupLeave(e,data.groupId)}
                        style={{ color: "#2074d4", marginLeft:"3px" }}
                    ><ExitToAppIcon/></Fab>
            
            </ListItem>
            <Divider variant="inset" component="li" />
        </React.Fragment>
        )
    }

    const renderUserSingle = (data) => {
        // console.log("render user single data",data);
        // console.log("render user single data IDENTTTT",ident);
        return (<React.Fragment key={data.id}>
            <ListItem alignItems="center" data-testid={`user-${data.id}`} onClick={() => openChatHistory(data.id, data.beraterName)}>
                <ListItemAvatar>
                    <Avatar alt={data.initials || ""} src={`data:image/png;base64,${data.picture}`} />
                </ListItemAvatar>
                <ListItemText
                    primary={data.beraterName || ""}
                />
                <span>
                {(data.telefonFirma !== null && data.telefonFirma !== "") && (
                    <Fab
                        size="small"
                        aria-label="Tel"
                        href={"tel:" + data.telefonFirma}
                        style={{ color: "#2074d4" }}
                    >
                        <PhoneIcon fontSize="small" />
                    </Fab>
                )}
                </span>
                <span>
                {/* next to the chat username is displayed red notification icon. If there are no new reminders, there is only red icon present. But if there are some new
                reminders, ther red icon is present and the number of the new notifications is displayed next to the icon. */}
                {localState.reminderCount>0 && data.id==getBeraterId() ? (
                    <Fab
                        size="small"
                        aria-label="Tel"
                        style={{ color: "red" }}
                    >
                        <NotificationsIcon fontSize="small" />
                        <span style={{marginBottom:"18px",marginLeft:"-5px", fontWeight:"bold"}}>{localState.reminderCount}</span>
                    </Fab>
                ):data.id==getBeraterId()?(
                    <Fab
                        size="small"
                        aria-label="Tel"
                        style={{ color: "red" }}
                    >
                        <NotificationsIcon fontSize="small" />
                    </Fab>
                ):""}
                </span>
            </ListItem>
            <Divider variant="inset" component="li" />
        </React.Fragment>
        )

    }

    const handleModalSave = () =>{
        let beraters = [];
        selected.map(s=>{
            beraters.push(s.value);
        })
        let groupName = localState.newGroupName;
        new DbTasks().getDataFromDb("api/Chat/CreateChatGroup", {
            beraterId: getBeraterId(),
            selectedBeraters:beraters,
            groupName:groupName
        },
            { method: "POST" })
            .then(res => {
                //console.log("Request res", res);
                if(res && res==true){
                    Notifications('success','Chatgruppe hinzugefÃ¼gt');
                    setLocalState({...localState, reload:true,modalOpen:false});
                }else{
                    Notifications('warning','Error happened while creating the chat group!');
                }
            })
        //console.log("modal save----beraterids------->",beraters);
    }

    const handleModalEdit = () =>{
        let beraters = [];
        selected.map(s=>{
            beraters.push(s.value);
        })
        let groupName = localState.newGroupName;
        new DbTasks().getDataFromDb("api/Chat/EditChatGroup", {
            groupId:localState.editGroupId,
            beraterId: getBeraterId(),
            selectedBeraters:beraters,
            groupName:groupName
        },
            { method: "POST" })
            .then(res => {
                //console.log("Request res", res);
                if(res && res == true){
                    Notifications('success','Chatgruppe bearbeitet');
                    setLocalState({...localState, reload:true, modalOpen:false});
                }else{
                    Notifications('warning','Error happened while editing the chat group!');
                }
            })
    }

    const handleGroupNameChange = (e) =>{
        setLocalState({...localState,newGroupName:e.target.value});
    }

    const setConfirmationOpen = (e,param,groupId)=>{
        setLocalState({...localState,confirmationOpen:param, groupToLeave:groupId});
        e.stopPropagation();
    }

    return (
        <Grid container spacing={0} alignContent='center' justify='center'>
            <Grid item lg={3} md={4} sm={6} xs={12} >
                <Paper className={classes.rootSearch}>
                    <InputBase
                        data-testid="search"
                        id="userSearch"
                        className={classes.input}
                        placeholder="Nutzer suchen"
                        onChange={(e) => {
                            if (e.currentTarget.value !== "") {
                                setLocalState({...localState, showClear: true });
                            }else{
                                setLocalState({...localState, showClear: false });
                            }
                        }}
                        onKeyPress={(e)  => {
                            if(e.key === "Enter"){
                                setLocalState({...localState, reload: !localState.reload });
                            }
                        }}
                    //inputProps={{ 'aria-label': 'search google maps' }}
                    />
                    {localState.showClear === true ?
                        <IconButton
                            data-testid="clear"
                            //type="submit"
                            onClick={() => {
                                setLocalState({...localState, reload: !localState.reload, showClear: false, showLoading: true });
                                document.getElementById("userSearch").value = "";
                            }}
                            className={classes.iconButton}
                            aria-label="search">
                            <Remove />
                        </IconButton> :
                        <IconButton
                            data-testid="search-icon"
                            onClick={() => setLocalState({...localState, reload: !localState.reload })}
                            className={classes.iconButton}
                            aria-label="search">
                            <SearchIcon />
                        </IconButton>

                    }

                </Paper>
                {/* <TextFields
                        //onChange={(e) => setSearch(e.currentTarget.value)}
                        //value={searchValue}
                        id="userSearch"
                        type="text"
                        label="User search"
                        variant="outlined"
                        fullWidth={true}
                        //error={true}
                        //helperText={"Wrong pass or username"}
                        onKeyPress={(e)  => {
                            if(e.key === "Enter"){
                                setReload(!reload);
                            }
                        }}
                        class={classes.elementSpacing}
                    // style={style.textField}
                    /> */}
                <List className={classes.root} key={-1}>
                {/* displaying chat groups -- start */}
                {/* <div key={'chatGroups'}><Typography variant="h6" style={{borderBottom:'2px solid #2074d4', textAlign:"left", marginTop:'10px', color:'#2074d4'}}>{"Chat gruppe"}
                    <span>
                    <Fab
                        size="small"
                        aria-label="Tel"
                        className={classes.button}
                        onClick={handleModalOpen}
                        style={{ color: "#2074d4" }}
                    >
                        <FaPlus fontSize="small" />
                    </Fab>
                    </span>
                        </Typography>{localState.chatGroups.map(item=>renderChatGroup(item))}
                </div> */}
                {/* displaying chat groups -- end */}
                {/* displaying reminder group -- start */}
                <div key={'reminder'}><Typography variant="h6" style={{borderBottom:'2px solid #2074d4', textAlign:"left", marginTop:'10px', color:'#2074d4'}}>{"Reminder"}</Typography>{renderUserSingle(localState.reminderData)}</div>
                {/* displaying reminder group -- end */}
                    {localState.groups && localState.groups.map(i =>{
                        let temp = false;
                        let user = [];
                        localState.usersList.map(u =>{
                        //return <h1>tzest</h1>
                        //console.log(u.group, i);
                            if(u.group == i.groupName && u.id!== getBeraterId())
                            {
                               temp = true
                               user.push(u)
                            }
                       })
                       if(temp == true){ return <div key={i.groupName}><Typography variant="h6" style={{borderBottom:'2px solid #2074d4', textAlign:"left", marginTop:'10px', color:'#2074d4'}}>{i.groupName}</Typography>{user.map(us=>renderUserSingle(us))}</div> }
                    })}    
                    {/* {localState.usersList && localState.usersList.map((item) => renderUserSingle(item))} */}
                </List>
            </Grid>
            <BackdropSpinner showLoading={localState.showLoading} />
            <Modal show={localState.modalOpen} onClose={handleModalClose} title={"Neue Chatgruppe hinzufÃ¼gen"} save={localState.isGroupEdit ? handleModalEdit :handleModalSave}>
                <div style={{margin:"0% 5% 0% 5%"}}>
                <TextField style={{marginBottom:"10px",width:"100%"}} size="small" label="Gruppenname" placeholder='Gruppenname:' value={localState.newGroupName} onChange={handleGroupNameChange}></TextField>
                <MultiSelect
                options={localState.options}
                value={selected}
                onChange={setSelected}
                labelledBy="Beraters"
                />
            </div>
            </Modal>
            <Dialog
                open={localState.confirmationOpen}
                keepMounted
                title={"Group leave confirmation"}
                description={`Are you sure you want to leave this chat group?`}
                onClose={(e) => setConfirmationOpen(e,false)}
                handleSubmit={(e)=>handleGroupLeave(e)}
            />
        </Grid>
    );
}

export default withRouter(UsersList);