import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Spinner from '../Reusable/Spinner';
import { useDispatch } from 'react-redux';
import { toggleSpinner } from '../../actions/AppActions';
import DbTasks from '../../db/DbTasks';
import { getBase64, getBeraterId } from '../../helpers/HelperFunctions';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
// import Paper from '@material-ui/core/Paper';
import moment from 'moment';
import { Button, ButtonGroup, GridList, GridListTile, GridListTileBar, IconButton, Paper } from '@material-ui/core';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import ImageIcon from '@material-ui/icons/Image';
import { Delete } from '@material-ui/icons';
import Notifications from '../Reusable/Notifications';
import { FaSort, FaSortDown, FaSortUp } from 'react-icons/fa';
import BackdropSpinner from '../Reusable/BackdropSpinner';
import { getDataFromDatabase } from '../../db/api';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
        },
    },
    test: {
        //margin: theme.spacing(1)
        marginTop: theme.spacing(1)
    },
    table: {
        width: '100%',
        '& th': {
            textTransform: 'uppercase',
            fontWeight: 'bold',
        }
    },
    tblContainer: {
        boxShadow: '5px 14px 17px 5px #d6d6d6',
        // borderTop: '1px solid grey',
    },
    green: {
        backgroundColor: '#a2cf6e'
    },
    show: {
        display: 'block',
        // height: '100%',
        // WebkitTransition: 'all 1s ease-in-out',

    },
    hide: {
        display: 'none',
        // zIndex: -999,
        // height: '0%',
        // WebkitTransition: 'all 1s ease-in-out',
    },
    sectionHeading: {
        borderBottom: '2px solid #1976d2',
        textTransform: 'uppercase',
        color: '#000000',
        marginTop: '10px'
    },
}));

function Kvp() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [subject, setSubject] = useState("");
    const [desc, setDesc] = useState("");
   // const [showForm, setShowForm] = useState(false);
    const [kvpState, setKvpState] = useState({
        suggestionList: [],
        loader: true,
        images: [],
        showForm: false,
        reloadTable: false,
    });

    let file_input = [];

    useEffect(() => {
        let beraterId = getBeraterId();
        getDataFromDatabase("api/Kvp/SuggestionList", { beraterId: beraterId }, { method: "POST" })
            .then(res => {
                //console.log("Monatsleistung, :", res);
                setKvpState({ ...kvpState, suggestionList: res, loader: false })
            })
            .catch(err => {
                setKvpState({ ...kvpState, loader: false });
                alert("Service not available");
            })
        return () => {
            console.log("Running cleanup function !");
        }
    }, [kvpState.reloadTable])

    const uploadData = () => {
        if(subject === "" || desc === "") {
            Notifications("warning", "Subject and Description are required.");
            return;
        }
        setKvpState({ ...kvpState, loader: true })
        let beraterId = getBeraterId();
        let data = {
            Name: subject,
            description: desc,
            Attachments: kvpState.images,
            CreatedBy: beraterId,
            endpoint: "api/Kvp/KvpUpload",
            method: "POST"
        };
        dispatch(toggleSpinner(true));
        new DbTasks().dataUpload(data)
            .then(res => {
                //console.log("Suggestion response :", res);
                if (res) {
                    dispatch(toggleSpinner(false));
                    setKvpState({...kvpState, showForm: false, images: [], reloadTable: !kvpState.reloadTable});
                    Notifications("success", "Suggestion sent.");
                }
                else {
                    dispatch(toggleSpinner(false));
                    alert("Suggestion upload went wrong.");
                }
                setSubject("");
                setDesc("");
            }).catch(() => {
                dispatch(toggleSpinner(false));
                alert("Suggestion upload went wrong.");
            });
    }

    const test = (show) => {
        console.log(show);
        setKvpState({...kvpState, showForm: show});;
    }


    const selectFile = event => {
        event.preventDefault();
        console.log("FI", file_input);
        file_input = document.createElement("input");
        file_input.multiple = "multiple";
        file_input.id = 'file_input_id';
        //file_input.setAtribute("data-testid", "file_input_id");
        file_input.addEventListener(
            "change",
            uploadFile.bind(this),
            false
        );
        file_input.type = "file";
        file_input.click();
    };

    const uploadFile = () => {
        //let images = [];
        Object.values(file_input.files).forEach(src => {
            let fileName = src.name;
            getBase64(src).then(src => {
                //dispatch(FotoAdd(null, src))
                let img = kvpState.images;
                img.push({ data: src, fileName: fileName });
                setKvpState({ ...kvpState, images: img })
                //console.log("Uploaded image : ", src)
            }
            );
        });
        //setKvpState({...kvpState, images})
    };

    const fotoMachen = () => {
        setKvpState({ ...kvpState, TakePhotoOpen: true, images: kvpState.images });
    };

    const removeImage = (idToRemove) => {
        console.log("Image to remove : ", idToRemove);
        let imagesWithoutDeletedOnes = kvpState.images.filter(e => e.fileName !== idToRemove);
        console.log("Deleted image name : ", idToRemove);

        console.log("Images left : ", imagesWithoutDeletedOnes);
        setKvpState({ ...kvpState, images: imagesWithoutDeletedOnes });
        // dispatch(FotoRemove(idToRemove));
    }


    return (
        <div>
            <Grid container spacing={0} justify="center" >
                <Grid item lg={6} md={8} sm={10} xs={11} className={kvpState.showForm ? classes.show : classes.hide} data-testid="form">
                <Grid item xs={12}>
                        <p className={classes.sectionHeading}>KVP-Daten</p>
                    </Grid>
                    <div >
                        <Grid container spacing={1} justify="center">
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                {/* <form noValidate autoComplete="off"> */}
                                <TextField
                                    data-testid="thema-vorschlag"
                                    id="sugSubject"
                                    label="Thema Vorschlag"
                                    fullWidth
                                    onChange={(e) => setSubject(e.currentTarget.value)}
                                    value={subject}
                                    //defaultValue=""
                                    variant="outlined"
                                    className={classes.test}
                                />
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <TextField
                                    data-testid="input-2"
                                    id="sugDescription"
                                    label="Hier Vorschlag beschreiben..."
                                    onChange={(e) => setDesc(e.currentTarget.value)}
                                    value={desc}
                                    multiline
                                    rows={4}
                                    fullWidth
                                    variant="outlined"
                                    className={classes.test}
                                />
                            </Grid>
                           
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <div className={classes.root}>
                                    <ButtonGroup fullWidth color="primary" variant="contained">
                                        <Button
                                            data-testid="foto-machen"
                                            variant="contained"
                                            //color="primary"
                                            onClick={fotoMachen}
                                            startIcon={<PhotoCameraIcon />}

                                        >
                                            {/* <Text tid="openCamera" /> */}
                                                    Kamera Ã¶ffnen
                                                </Button>
                                        {/* {" "}
                                    &nbsp; */}
                                        <Button
                                            data-testid="select-file"
                                            onClick={selectFile}
                                            variant="outlined"
                                            startIcon={<ImageIcon />}

                                        >
                                            {/* <Text tid="addPhoto" /> */}
                                                    Fotos hinzufÃ¼gen
                                                </Button>
                                        {/* {state.signDialogOpen && (<Dialog open={state.signDialogOpen} onClose={closeSignDialog} image={imageBase64.data} />)} */}
                                    </ButtonGroup>
                                </div>
                                
                                {/* {kvpState.loader && <CircularProgress size={30} className={classes.buttonProgress} />} */}
                                {/* </form> */}
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <GridList cellHeight={160} cols={3}>
                                    {kvpState.images.map((tile, index) => (
                                        <GridListTile cols={1} key={index} style={{ border: '1px solid #DDD' }}>
                                            <img src={tile.data} alt="" />
                                            <GridListTileBar
                                                onClick={() => removeImage(tile.fileName)}
                                                actionIcon={
                                                    <IconButton >
                                                        <Delete color="secondary" />
                                                    </IconButton>
                                                }
                                            />
                                        </GridListTile>
                                    ))}
                                </GridList>
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xs={12} data-testid="send">
                            <Spinner
                                    handleSubmit={() => uploadData()}
                                    fullWidth={true}
                                    customStyle={classes.test}
                                    buttonText="Vorschlag senden" />
                            </Grid>

                        </Grid>
                    </div>
                </Grid>
                <Grid item lg={11} md={11} sm={11} xs={12}>
                    <TableContainer className={classes.tblContainer}>
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell colSpan={2}>
                                        <Button
                                            data-testid="open-form"
                                            onClick={() => test(!kvpState.showForm)}
                                            size="small" variant="outlined" color="primary"
                                            endIcon={kvpState.showForm ? <FaSortDown  style={{ marginBottom: '5px' }} /> : <FaSortUp  style={{ marginTop: '5px' }} /> }
                                            >Neuer Vorschlag ?</Button>
                                    </TableCell>
                                    <TableCell colSpan={2} style={{ textAlign: 'left' }}>Ãbersicht VorschlÃ¤ge</TableCell>

                                </TableRow>
                                <TableRow>
                                    <TableCell>Themen</TableCell>
                                    <TableCell align="left">Bezeichnung</TableCell>
                                    <TableCell align="left">Feedback</TableCell>
                                    <TableCell align="left">Datum</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {kvpState.suggestionList.map((row, index) => (
                                    <TableRow key={index} className={row.feedback !== null ? classes.green : ""}>
                                        <TableCell>{row.name}</TableCell>
                                        {/* <TableCell component="th" scope="row">
                {row.name}
              </TableCell> */}
                                        <TableCell align="left">{row.description}</TableCell>
                                        <TableCell align="left" >{row.feedback}</TableCell>
                                        <TableCell align="left">{row.created ? moment(row.created).format("DD.MM.YYYY") : ""}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
            <BackdropSpinner showLoading={kvpState.loader} />
        </div>
    )
}

export default withRouter(Kvp);