import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import DbTasks from '../../db/DbTasks';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment-with-locales-es6';
import BackdropSpinner from '../Reusable/BackdropSpinner';
import CardContainer from '../Reusable/CardContainer';
import { FaEuroSign } from 'react-icons/fa';
import { getBeraterId } from '../../helpers/HelperFunctions';
import {getDataFromDatabase} from '../../db/api';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        margin: theme.spacing(2),
        //fontFamily: 'sans-serif',
    },
    cardRoot: {
        minWidth: 245,
        height: 245,
        margin: theme.spacing(2)
    },
    paper: {
        padding: theme.spacing(0),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        //backgroundColor: '#ffffa7'
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 20,
    },
    pos: {
        marginBottom: 12,
    },
    green: {
        color: 'green'
    },
    red: {
        color: 'red'
    }
}));

function Monatsleistung(props) {
    const classes = useStyles();
    const [premieState, setPremieData] = useState({
        tagesData: [],
        minPremia: 0,
        maxPremia: 0, 
        correction: []
    });
    //const [tagesData, setTagesData] = useState([]);
    const [showLoading, setLoading] = useState(true);

    useEffect(() => {
        let beraterId = getBeraterId();
        let pastYear = new Date();
        pastYear.setFullYear(pastYear.getFullYear() - 1);
        getDataFromDatabase("api/Leistungspremie/GetMonatPremie", { startDate: pastYear, endDate: new Date(), beraterId: beraterId }, { method: "POST" })
            .then(res => {
                console.log("Monatsleistung, :", res);
                setPremieData({...premieState, tagesData: res.data, minPremia: res.minPremia, maxPremia: res.maxPremia, correction: res.correction })
                //setTagesData(res.data);
                setLoading(false);
            })
            .catch(err => {
                setLoading(false);
                alert("Service not available");
            })
        return () => {
            console.log("Running cleanup function !");
        }
    }, [])

    const openTagesLeistung = (month, year) => {
        props.history.push("/tagesleistung/" + month + "/" + year);
    }

    // const getHighestPremie = (data) => {
    //     //let premia =  data.sort((a, b) => a.premia < b.premia ? 1 : -1)[0].premia;
    //     let premia = data.reduce((a, b) => a + b.premia, 0);
    //     return premia;
    // }
    return (
        <div className={classes.root}>
            {/* Monatsleistung */}
            <Grid container spacing={1} alignContent='center' justify='center'>
                {premieState.tagesData && premieState.tagesData.map((el, index) => {
                    //   return  <li key={el.weekNr}>{el.weekNr}</li>
                    return <Grid item lg={3} md={4} sm={6} xs={12} key={index}>
                        <Paper className={classes.paper} onClick={() => openTagesLeistung(el.month, el.year)} data-testid={`paper-${el.month}-${el.year}`}>
                            <CardContainer
                                title={`${moment(el.month, 'M').locale('de').format('MMMM')} / ${el.year}`}
                                number = {el.premia}
                                subtitle={"Summe MonatsprÃ¤mie"}
                                class={premieState.minPremia === el.premia ? "red" : premieState.maxPremia === el.premia ? "green" : "blue"}
                                mainIcon={<FaEuroSign size={"4em"} color="white" />}
                            />
                            {/* {cardDetails({ title: `${moment(el.month, 'M').format('MMMM') } / ${el.year}`, number: el.lista })} */}
                        </Paper>
                        {/* {el.weekNr} */}
                    </Grid>
                })}
                {premieState.tagesData.length === 0 && <p>Es gibt keine Daten fÃ¼r die letzten 12 Monate.</p>}
                {/* <Grid item lg={12} sm={12} xs={12}>

                </Grid> */}
            </Grid>
            <BackdropSpinner showLoading={showLoading} />
        </div>
    )
}

export default withRouter(Monatsleistung);